import { createRouter, createWebHistory } from 'vue-router';
import { authorizationStore } from '@/store/authorization';
import { encode } from '@/assets/js/UrlCoding';
import { Capacitor } from '@capacitor/core';
import $localStorage from '@/capacitor/localStorageWrapper';
import authentication from './authentication';
import publicShoots from './publicShoots';
import appInsights from '@/AppInsights';
import account from './account';
import landers from './landers';
import app from './app';
import shooterAxios from '@/assets/js/axios/shooterSideAPI';
const routes = [
	{
		name: '404 Not Found',
		path: '/404',
		name: '404',
		meta: {
			public: true,
			hideHeader: true,
			hideFooter: true,
		},
		component: () => import('../views/404.vue'),
	},
	{
		name: '',
		path: '/',
		component: () => import('../views/Home.vue'),
		meta: {
			public: true,
			blockNative: true,
		},
	},
	{
		name: 'About',
		path: '/about',
		component: () => import('../views/About.vue'),
		meta: {
			public: true,
		},
	},
	{
		name: 'Debug',
		path: '/debug',
		component: () => import('../views/Debug.vue'),
		meta: {
			public: true,
		},
	},
	...landers,
	...account,
	...authentication,
	...app,
	...publicShoots,
];

let loginPath = sessionStorage.getItem('loginPath') || '/login';

shooterAxios.get('/use-login-form').then(({ data }) => {
	if (data.use) {
		if (!data.use.includes('/login') || data.use.split('/').length > 1)
			return console.error('Invalid login path: ', data.use);
		loginPath = data.use;
	}
});

const isNative = Capacitor.isNativePlatform();
const router = createRouter({
	// 4. Provide the history implementation to use. We are using the hash history for simplicity here.
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		return { top: 0 };
	},
	routes, // short for `routes: routes`
});

let authStore = null;
let totalRoutes = 0;
const routesMap = routes.map((x) => x.path.toLowerCase());
const routesMapRegex = routes.map((x) =>
	x.path.toLowerCase().replace(/:\w+/gi, '([^/]+)')
);

router.beforeEach(async (to, from) => {
	totalRoutes++;

	if (!authStore) {
		authStore = authorizationStore();
	}
	if (!authStore.initialized) {
		await authStore.init();
	}

	if (to.path.includes('/login') && to.path !== loginPath) {
		console.log('Login path redirecting: ', to.path, loginPath);

		return {
			replace: true,
			path: loginPath,
		};
	}

	//For first time native app user's use the onboarding page instead.
	if (isNative && totalRoutes === 1) {
		const isInitialized = await $localStorage.get('appInitialized');
		if (!isInitialized) {
			return {
				replace: false,
				path: '/app/on-board',
			};
		}
	}
	/**
	 * Mobile guards
	 */

	//If iOS or Android remove the home page and make the default experience within app
	if (to.meta && to.meta.blockNative) {
		if (isNative) {
			if (authStore.isLoggedIn()) {
				return {
					replace: true,
					path: '/app',
				};
			} else {
				return {
					replace: true,
					path: loginPath,
				};
			}
		}
	}

	//If web, and page is app only redirect to login or app
	if (to.meta && to.meta.requireNative) {
		if (!isNative) {
			if (authStore.isLoggedIn()) {
				return {
					replace: true,
					path: loginPath,
				};
			} else {
				return {
					replace: true,
					path: '/app',
				};
			}
		}
	}

	if (!routesMap.includes(to.path.toLowerCase())) {
		const matchesRegex = routesMapRegex
			.map((x) => {
				const re = new RegExp(x);
				return re.test(to.path.toLowerCase());
			})
			.reduce((a, b) => {
				return a || b;
			});
		if (!matchesRegex) {
			console.debug('Redirect to: ', `${to.fullPath} (${encode(to.fullPath)})`);
			return {
				replace: true,
				path: '/404',
				query: {
					redirect: encode(from.fullPath),
				},
			};
		}
	}

	//Public routes do not need any guarding
	if (to.meta.public) return true;

	if (!authStore.isLoggedIn() && to.path != '/login') {
		console.debug('Redirect to: ', `${to.fullPath} (${encode(to.fullPath)})`);
		return {
			replace: true,
			path: loginPath,
			query: {
				redirect: encode(to.fullPath),
			},
		};
	}

	//Auth checks passed
	return true;
});

router.afterEach((to) => {
	if (!authStore) {
		authStore = authorizationStore();
	}
	if (!authStore.initialized) {
		authStore.init();
	}

	appInsights.trackPageView({
		properties: {
			pageType: to.meta.public ? 'public' : 'authenticated',
			user: authStore.userId,
			name: `${authStore.firstName} ${authStore.lastName}`,
		},
		name: to.name,
	});
	if (to.name) document.title = `${to.name} | ShootPro Cloud`;
	else document.title = `ShootPro Cloud`;

	try {
		if (to.meta.public) {
			document.querySelector('.grecaptcha-badge').style.display = 'block';
		} else {
			document.querySelector('.grecaptcha-badge').style.display = 'none';
		}
	} catch {}

	window.scrollTo(0, 0);
});

export default router;
