<template>
	<v-layout class="bg">
		<v-main
			:class="`mx-auto ${
				$route.meta.fullWidth || $vuetify.display.width <= 1140
					? ''
					: 'limitMaxWidth'
			}`"
		>
			<v-container class="pb-0">
				<div style="min-height: 16px" class="mb-n5">
					<v-progress-linear
						v-show="progressBarStore.show"
						:color="progressBarStore.color"
						indeterminate
					/>
				</div>
				<v-card class="mt-6 pa-3 rounded-xl">
					<v-img src="/assets/spclogo.webp" height="50px" />
					<v-card-title v-if="user" class="pa-0">
						<p class="text-subtitle-2">{{ greeting }}</p>
						<p class="mt-n2">{{ fullName }}</p>
					</v-card-title>
				</v-card>
			</v-container>
			<slot />
		</v-main>
		<v-bottom-navigation color="deep-orange" elevation="12">
			<v-btn to="/app"><v-icon size="x-large">mdi-home-outline</v-icon></v-btn>
			<v-btn
				v-if="activeShoot && activeShoot.announcements.length > 0"
				to="/app/profile"
			>
				<v-badge dot color="red">
					<v-icon size="x-large">mdi-account-outline</v-icon>
				</v-badge>
			</v-btn>
			<v-btn v-else to="/app/profile">
				<v-icon size="x-large">mdi-account-outline</v-icon>
			</v-btn>
			<v-btn v-show="shootStore.clubId" to="/app/shoot">
				<v-icon size="x-large">mdi-trophy-outline</v-icon>
			</v-btn>
			<v-menu location="top">
				<template #activator="{ props }">
					<v-btn v-bind="props">
						<v-icon size="x-large">mdi-menu</v-icon>
					</v-btn>
				</template>
				<v-list min-width="125">
					<v-list-item @click="reload">
						<v-list-item-title class="text-button"> Refresh </v-list-item-title>
					</v-list-item>
					<v-list-item @click="$router.push('/about')">
						<v-list-item-title class="text-button"> About </v-list-item-title>
					</v-list-item>
					<v-list-item @click="$router.push('/app/my-other-shoots')">
						<v-list-item-title class="text-button">
							My Shoots
						</v-list-item-title>
					</v-list-item>
					<v-divider />
					<v-list-item @click="$router.push('/logout')">
						<v-list-item-title class="text-button"> Logout </v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-bottom-navigation>
	</v-layout>
</template>

<script>
import dayjs from 'dayjs';
import { mapState, mapStores } from 'pinia';
import { shootStore } from '@/store/shoot';
import { authorizationStore } from '@/store/authorization';
import { progressBarStore } from '@/store/progressBar';
export default {
	name: 'AuthorizedLayout',
	components: {},
	data() {
		return {};
	},
	computed: {
		...mapStores(shootStore),
		...mapState(authorizationStore, ['user']),
		...mapStores(progressBarStore),
		activeShoot() {
			if (!this.shootStore.response) {
				return null;
			}
			return this.shootStore.response.activeShoot;
		},
		fullName() {
			return `${this.user.firstName} ${this.user.lastName}`;
		},
		greeting() {
			const m = dayjs();
			let g = null; //return g
			if (!m || !m.isValid()) {
				return;
			} //if we can't find a valid or filled moment, we return.
			let split_afternoon = 12; //24hr time to split the afternoon
			let split_evening = 17; //24hr time to split the evening
			let morning_starts = 5; //5 am
			let currentHour = parseFloat(m.format('HH'));
			if (currentHour >= split_afternoon && currentHour < split_evening) {
				g = 'afternoon';
			} else if (
				currentHour >= split_evening ||
				currentHour <= morning_starts
			) {
				g = 'evening';
			} else {
				g = 'morning';
			}
			if (!g) return 'Welcome';
			return `Good ${g}`;
		},
	},
	methods: {
		async reload() {
			if (this.shootStore.forceClubId && this.shootStore.forceShootId) {
				const confirm = await this.$confirm({
					text: `Do you want to keep ${this.shootStore.shootName} active?`,
					noButtonText: 'Go Home',
					yesButtonText: 'Keep shoot active',
				});
				if (confirm) this.shootStore.getActiveShootInfo();
				else this.$router.go(0);
			} else {
				this.$router.go(0);
			}
		},
	},
};
</script>

<style scoped>
@media (min-width: 1441px) {
	.limitMaxWidth {
		max-width: 1440px;
		/* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
			0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
	}
}
.bg {
	background: url('/assets/bg.webp');
	background-size: cover;
}
</style>
