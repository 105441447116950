<template>
	<v-layout class="bg">
		<v-main
			:class="`mx-auto ${
				$route.meta.fullWidth || $vuetify.display.width <= 1140
					? ''
					: 'limitMaxWidth'
			}`"
		>
			<v-container class="pb-0">
				<v-card class="mt-6 pa-3 rounded-xl">
					<v-img src="/assets/spclogo.webp" height="50px" />
				</v-card>
			</v-container>
			<slot />
		</v-main>
		<v-bottom-navigation
			v-if="
				(user?.authorized || $route.path !== '/view/shoots') &&
				$route.path !== '/signup'
			"
			color="deep-orange"
			elevation="12"
		>
			<v-btn
				v-if="$route.path !== '/view/shoots' && $route.path.includes('/view')"
				to="/view/shoots"
			>
				<v-icon size="x-large">mdi-undo</v-icon>
			</v-btn>
			<v-btn v-if="user?.authorized" to="/app">
				<v-icon size="x-large">mdi-home-outline</v-icon>
			</v-btn>
			<v-btn v-if="user?.authorized" to="/app/profile">
				<v-icon size="x-large">mdi-account-outline</v-icon>
			</v-btn>
			<v-btn v-if="!user?.authorized" to="/login">
				<v-icon size="x-large">mdi-account-arrow-right-outline</v-icon>
			</v-btn>
			<v-menu location="top">
				<template #activator="{ props }">
					<v-btn v-bind="props">
						<v-icon size="x-large">mdi-menu</v-icon>
					</v-btn>
				</template>
				<v-list min-width="125">
					<v-list-item @click="reload">
						<v-list-item-title class="text-button"> Refresh </v-list-item-title>
					</v-list-item>
					<v-list-item @click="$router.push('/about')">
						<v-list-item-title class="text-button"> About </v-list-item-title>
					</v-list-item>
					<v-list-item
						v-if="user?.authorized"
						@click="$router.push('/app/my-other-shoots')"
					>
						<v-list-item-title class="text-button">
							My Shoots
						</v-list-item-title>
					</v-list-item>
					<v-divider v-if="user?.authorized" />
					<v-list-item v-if="user?.authorized" @click="$router.push('/logout')">
						<v-list-item-title class="text-button"> Logout </v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-bottom-navigation>
	</v-layout>
</template>

<script>
import { authorizationStore } from '@/store/authorization';
import { mapState } from 'pinia';

export default {
	name: 'PublicLayout',
	data() {
		return {};
	},
	computed: {
		...mapState(authorizationStore, ['user']),
	},
	methods: {
		async reload() {
			this.$router.go(0);
		},
	},
};
</script>

<style scoped>
@media (min-width: 1441px) {
	.limitMaxWidth {
		max-width: 1440px;
		/* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
			0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
	}
}
.bg {
	background: url('/assets/bg.webp');
	background-size: cover;
}
</style>
