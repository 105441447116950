// @ts-nocheck
import { defineStore } from 'pinia';
import promiseDefer from '@/assets/js/deferedPromise';
//Used to allow the parent to resolve the promise

// You can name the return value of `defineStore()` anything you want, but it's best to use the name of the store and surround it with `use` and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const alertStore = defineStore('alert', {
	// arrow function recommended for full type inference
	state: () => {
		return {
			show: false,
			topDown: false,
			buttonText: 'Dismiss',
			bodyText: '',
			header: '',
			titleColor: '',
			persist: false,
			p: null,
			fullWidth: false,
		};
	},
	actions: {
		/**
		 * Displays the snackbar
		 * @param {Object} opts
		 * @param {String} opts.text
		 * @param {String} opts.title
		 * @param {String} opts.buttonText
		 * @param {Number} opts.titleColor effects the header color (optional)
		 * @param {Boolean} opts.persistent
		 * @param {Boolean} opts.fullWidth
		 */
		async showAlert(opts) {
			this._reset();
			this.p = new promiseDefer();

			if (typeof opts === 'string') {
				this.bodyText = opts;
				this.show = true;
				return this.p.promise;
			}
			this.bodyText = opts.text;
			this.header = opts.title || '';
			this.titleColor = opts.titleColor || '';
			this.persist = !!opts.persistent;
			this.buttonText = opts.buttonText || 'Dismiss';
			this.fullWidth = opts.fullWidth || false;
			this.show = true;
			this.p.promise.then(() => {
				this.p = null;
			});
			return this.p.promise;
		},
		async hideAlert() {
			this.show = false;
		},
		async _reset() {
			this.show = false;
			this.topDown = false;
			this.buttonText = 'Dismiss';
			this.bodyText = '';
			this.header = '';
			this.titleColor = 'info';
			this.persist = false;
			this.fullWidth = false;
		},
	},
});
