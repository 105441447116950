export default [
	{
		name: 'Download Today',
		path: '/landing/appstores',
		component: () => import('../views/landers/AppStores.vue'),
		meta: {
			public: true,
			// requireNative: true,
			// layout: 'fullScreen',
		},
	},
];
