// @ts-nocheck
import { defineStore, mapActions } from 'pinia';
import { progressBarStore } from './progressBar';
import $shooter from '@/assets/js/axios/shooterSideAPI';
import $localStorage from '@/capacitor/localStorageWrapper';
import { cloneDeep } from 'lodash';
const progressBar = mapActions(progressBarStore, [
	'showProgressBar',
	'hideProgressBar',
]);
// You can name the return value of `defineStore()` anything you want, but it's best to use the name of the store and surround it with `use` and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const shootStore = defineStore('shoot', {
	// arrow function recommended for full type inference
	state: () => {
		return {
			shoot: null,
			hoa: false,
			haa: false,
			clubId: null,
			shootId: null,
			association: null,
			shootName: null,
			categories: null,
			events: null,
			response: null,
			experience: 'active-shoot',
			futureShoots: null,
			pastShoots: null,
			forceClubId: null,
			forceShootId: null,
			_activeShootBackup: null,
			_responseBackup: null,
		};
	},
	actions: {
		async getActiveShootInfo(forceClubId = null, forceShootId = null) {
			if (this._activeShootBackup) {
				this.shoot = cloneDeep(this._activeShootBackup);
				this.response = cloneDeep(this._responseBackup);
				this.experience = 'active-shoot-switch-back';
				this._activeShootBackup = null;
				this._responseBackup = null;
			}

			progressBar.showProgressBar();
			if (forceClubId && forceShootId) {
				this.forceClubId = forceClubId;
				this.forceShootId = forceShootId;
			}
			if (this.forceClubId && this.forceShootId) {
				this.experience = 'active-shoot-forced';
			}

			const { data: res } = await $shooter
				.get('/app/dashboard', {
					params: {
						activeClub: this.forceClubId || undefined,
						activeShoot: this.forceShootId || undefined,
					},
				})
				.finally(() => {
					progressBar.hideProgressBar();
				});

			this.response = null;
			const data = res;
			this.response = data;
			const activeShoot = data.activeShoot;

			if (data.pastShoots) {
				this.pastShoots = data.pastShoots;
			}
			if (data.futureShoots) {
				this.futureShoots = data.futureShoots;
			}

			if (activeShoot) {
				this.shoot = activeShoot;
				this.clubId = activeShoot.ClubId;
				this.shootId = activeShoot.ShootId;
				this.association = activeShoot.Association;
				this.shootName = activeShoot.ShootName;
				this.events = activeShoot.allEvents;
				this.categories = activeShoot.categories;
				this.loadHxaInfo();
				this.experience = 'active-shoot';
			}

			progressBar.hideProgressBar();
			return res;
		},
		async loadShoot(ClubId, ShootId) {
			console.info('loadShoot', ClubId, ShootId);
			progressBar.showProgressBar();
			const { data } = await $shooter
				.get(`/shoot/club/${ClubId}/shoot/${ShootId}`)
				.finally(() => {
					progressBar.hideProgressBar();
				});
			if (this.shoot) {
				this._activeShootBackup = cloneDeep(this.shoot);
				this._responseBackup = cloneDeep(this.response);
				this.experience = 'shoot-active-viewing-inactive';
			} else {
				this.experience = 'shoot-inactive';
			}
			this.clubId = ClubId;
			this.shootId = ShootId;
			this.response = data;
			this.shoot = data;
			this.events = data.allEvents;
			this.shootName = data.ShootName;
			this.categories = data.categories;
			this.association = data.Association;
			this.loadHxaInfo();
		},
		async loadHxaInfo() {
			if (!this.shoot) {
				return console.error('Cannot load HXA info without shoot details');
			}

			if (this.shoot.hasHOA) {
				this.hoa = this.events.find((x) => x.HOA_UseForClass);
			}

			if (this.shoot.hasHAA) {
				this.haa = this.events.find((x) => x.HAA_UseForClass);
			}
		},
	},
});

window.addEventListener('logout', () => {
	console.info('logout event');
	shootStore().$patch({
		clubId: null,
		shootId: null,
		association: null,
		shootName: null,
		events: null,
		response: null,
		experience: 'active-shoot',
	});
	$localStorage.delete('cacheDashboardResult');
});
