import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { version, hash } from '../package.json';
import { authorizationStore } from './store/authorization';
import { shootStore } from './store/shoot';
import { Capacitor } from '@capacitor/core';
const isNative = Capacitor.isNativePlatform();
const platform = Capacitor.getPlatform();

const AI_KEY = import.meta.env.VITE_APPINSIGHTS;
let insightsDump = '';
if (!AI_KEY) {
	insightsDump = 'not set';
} else if (AI_KEY.includes('7ffdf77f')) {
	insightsDump = 'dumpster';
} else if (AI_KEY.includes('1c258eb9')) {
	insightsDump = 'next';
} else if (AI_KEY.includes('9df844c5')) {
	insightsDump = 'production';
}
console.debug(`ℹ️ USING APP INSIGHTS ACCOUNT:"${insightsDump}"`);

const appInsights = new ApplicationInsights({
	config: {
		connectionString: AI_KEY,
		enableUnhandledPromiseRejectionTracking: true,
		enableAjaxErrorStatusText: true,
		enableAjaxPerfTracking: true,
		enableRequestHeaderTracking: true,
		enableResponseHeaderTracking: true,
		autoTrackPageVisitTime: true,
		autoUnhandledPromiseInstrumented: true,
		loggingLevelConsole: 0,
		samplingPercentage: 25,
	},
});

let shoot_store = null;
let authStore = null;
appInsights.addTelemetryInitializer(async (item) => {
	if (!item['data']) item['data'] = {};
	try {
		if (!authStore) {
			authStore = authorizationStore();
		}
		if (!shoot_store) {
			shoot_store = shootStore();
		}
		if (!authStore.initialized) {
			await authStore.init();
		}
		item.data.isNative = isNative;
		item.data.platform = platform;
		item.data.experience = {};
		item.data.experience['club'] = shoot_store.clubId;
		item.data.experience['shoot'] = shoot_store.shootId;
		item.data.experience['shootName'] = shoot_store.shootName;
		item.data.experience['experience'] = shoot_store.experience;
		item.data.experience['user'] = authStore.userId;
		item.data.experience['name'] = authStore.user
			? `${authStore.user.firstName || 'not'} ${
					authStore.user.lastName || 'set'
			  }`
			: authStore.userId;
	} catch (ex) {
		console.error('cannot link experience info', ex);
		appInsights.trackException({
			exception: ex,
			properties: {
				note: 'appinsights cannot track experience info',
			},
		});
	}
	item.data['host'] = window.location.hostname;
	item.data['path'] = window.location.pathname;
	item.data['UA'] = navigator.userAgent;
	item.data['version'] = `v${version}@${hash}`;
	if (window.deviceId) {
		item.data['version'] = window.deviceId;
	}
	return item;
});
appInsights.loadAppInsights();

export default appInsights;
